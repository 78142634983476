import React from 'react';
import PortableText from 'react-portable-text';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function SiteCard({ info }) {
  console.log(info._rawNotes);

  return (
    <SiteCardStyles>
      <div className="site-card__header" />
      <div className="site-card__content">
        <div className="sub-header">
          <h3>
            {info.name} - <span className="customer">{info.customer}</span>
          </h3>
        </div>

        <div>
          <p>
            <strong>Fire Alarm:</strong> <br />
            {info.fireAlarm}
          </p>

          <p>
            <strong>Access Control:</strong> <br /> {info.access}
          </p>
          <p>
            <strong>Intruder Master Remote Code:</strong> <br />
            {info.irmc}
          </p>
          <p>
            <strong>Intruder Engineers Code:</strong> <br />
            {info.iec}
          </p>
          <p>
            <strong>CCTV Master Code:</strong> <br /> {info.cctvmc}
          </p>
          <p>
            <strong>CCTV Engineers Code:</strong> <br /> {info.cctvec}
          </p>
          <p>
            <strong>Other Code(s):</strong> <br /> {info.other}
          </p>

          <div className="site-card__notes">
            <h4 className="notes__header">Additional Notes</h4>
            <PortableText
              content={info._rawNotes}
              projectId="ytvnpwhq"
              dataset="production"
            />
          </div>
        </div>
      </div>
    </SiteCardStyles>
  );
}

SiteCard.propTypes = {
  info: PropTypes.object.isRequired,
};

const SiteCardStyles = styled.div`
  width: 98%;
  margin: 60px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  .site-card__header {
    background: var(--accent);
    height: 10px;
  }
  .site-card__content {
    padding: 0 20px;
    h3 {
      font-size: 24px;
    }
    span {
      font-weight: normal;
      font-size: 18px;
    }
  }
  .site-card__notes {
    .notes__header {
      padding: 0 30px 0 10px;
      margin: 0;
      background: white;
      position: relative;
      display: inline-block;
      top: -35px;
      z-index: 1000;
    }
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 50px 0;
  }

  img {
    max-width: 50%;
  }
`;
