import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PortableText from 'react-portable-text';
import SEO from '../../../../components/layout/SEO';
import SiteCard from '../../../../components/engineer/SiteCard';
import useForm from '../../../../utils/useForm';

export default function CodesPage({ data }) {
  const [auth, setAuth] = useState(false);
  const [error, setError] = useState();
  const [currentDC, setCurrentDC] = useState(null);

  const { inputs, handleChange } = useForm({
    password: '',
  });

  async function handleFormSubmit(event) {
    event.preventDefault();

    const match = await data.sanityEngineer.passcodes.find(
      (pass) => inputs.password === pass.password
    );

    if (match) {
      setAuth(true);
      setCurrentDC(match);
    }

    if (!match) {
      setError(true);
    }
  }

  if (!auth) {
    return (
      <LogInStyles className="container">
        <SEO title="Engineering" index={false} />

        <form onSubmit={(event) => handleFormSubmit(event)}>
          <div className="form-header">
            <p>Log In</p>
          </div>

          <fieldset>
            <label htmlFor="password">
              <p>Please enter your DC password</p>
              <input
                type="password"
                name="password"
                value={inputs.password}
                onChange={handleChange}
                required
              />
            </label>

            <button type="submit">Log In</button>

            {error ? <p className="form-error">Incorrect Password</p> : ''}
          </fieldset>
        </form>
      </LogInStyles>
    );
  }

  return (
    <div className="container" style={{ paddingTop: '30px' }}>
      <SEO title="Engineering" index={false} />

      <h2 style={{ marginBottom: '30px' }}>
        Delivery Center: {currentDC?.name}
      </h2>

      <div>
        <PortableText content={data.sanityEngineer._rawContent} />
      </div>

      <div className="uksi-sites">
        {data.sanityEngineer.sites.map((site) => (
          <SiteCard key={site._key} info={site} />
        ))}
      </div>
    </div>
  );
}

export const query = graphql`
  {
    sanityEngineer {
      _rawContent
      sites {
        _key
        name
        logo {
          asset {
            gatsbyImageData(width: 150, height: 150)
          }
        }
        customer
        fireAlarm
        access
        irmc
        iec
        cctvmc
        cctvec
        other
        _rawNotes
      }

      passcodes {
        name
        password
      }
    }
  }
`;

CodesPage.propTypes = {
  data: PropTypes.object,
};

const LogInStyles = styled.div`
  padding-top: 60px;
  form {
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .form-header {
      background: var(--accent);
      color: var(--white);
      padding: 5px 10px;
      p {
        margin: 0;
        color: var(--white);
        font-weight: 600;
      }
    }
    /* fieldset {
      outline: none;
      border: none;
      z-index: 99;
    } */
    input {
      z-index: 100;
      display: block;
      padding: 7px;
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      color: white;
      background-color: var(--accent);
      padding: 5px 30px;
      margin: 20px 0;
      font-family: 'Montserrat', sans-serif;
      display: block;
    }
    .form-error {
      color: red;
    }
  }
`;
