import { useState } from 'react';

export default function useForm(initial = {}) {
  // create state to store form inputs
  const [inputs, setInputs] = useState(initial);

  // write a change handling function that updates state
  function handleChange(event) {
    // set the inputs into state
    setInputs({
      // spread in current inputs
      ...inputs,
      [event.target.name]: event.target.value,
    });
  }

  // expose inputs and updating method to the component using it
  return {
    inputs,
    handleChange,
  };
}
